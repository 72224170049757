.tab-sec-wrp {
    .tab-header {
        button {
            font-family: 'Andika', sans-serif;
            font-size: 18px;
            color: #5D5848;
            border-radius: 16px 16px 0px 0px;
            background: rgba(15, 10, 1, 0.50);
            min-width: 150px;
            &.Mui-selected{
                color: #FFF4EE;
                background: #0F0A01;
            }
        }
        .MuiTabs-indicator{
            background: #0F0A01;
        }
    }
    .tab-body{
        background: #0F0A01;
        border-radius: 0px 24px 24px 24px;
    }
}
