.accountTab-section {
    padding: 0;

    .red-btn {
        background-color: #440F5A;
        color: #fff !important;
        margin-bottom: 25px;
        text-transform: none;
    }

    .news-con-wrp {
        background: #fff;
        border-radius: 24px;
        padding: 24px;
    }

}

.account-design {
    .img-upload-wrp {
        .upload-img {
            label {
                width: 100%;
                height: 100%;
                border-radius: 0;
                border: 1px dashed #FF3D00;
                display: block;

                svg {
                    width: 35px;
                    height: 35px;
                }
            }
        }

        &.logo-list {
            .each-img-blk {
                width: 300px;
                display: block;
                height: 160px;
                border: none;
                border-radius: 0;
                margin: unset;
                float: left;

                img {
                    border-radius: 0;
                    object-fit: contain;
                }

                button {
                    background: #BF0000;
                    border-radius: 100%;
                    padding: 3px;

                    svg {
                        width: 25px;
                        height: 25px;

                        path {
                            fill: #fff;
                        }
                    }
                }
            }
        }
    }
}

.clr-box {
    border: 1px solid transparent;
    padding: 25px;
    border-radius: 7px;
    text-align: center;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    margin-top: 5px;
    position: relative;
    margin-bottom: 5px;

    span {
        border-radius: 5px;
        background: rgba(255, 255, 255, 0.75);
        color: #440F5A;
        padding: 3px 7px;
        opacity: 0;
        transition: 0.5s all;
    }

    &:hover span {
        opacity: 1;
        transition: 0.5s all;
    }

    &:active,
    &:focus,
    &:hover,
    &.selected {
        &::after {
            content: '';
            width: calc(100% + 10px);
            height: calc(100% + 10px);
            display: block;
            border: 1px solid #440F5A;
            position: absolute;
            left: -5px;
            top: -5px;
            border-radius: 7px;
        }
    }

    &.light {
        background: -webkit-linear-gradient(left, #fff, #fff 50%, #F5F0E7 50%, #F5F0E7);
    }

    &.dark {
        background: -webkit-linear-gradient(left, #0F0A01, #0F0A01 50%, #211E1D 50%, #211E1D);
    }

    &.btnClr_1 {
        background: -webkit-linear-gradient(left, #638EFF, #638EFF 50%, #4075FF 50%, #4075FF);
    }

    &.btnClr_2 {
        background: -webkit-linear-gradient(left, #8FC652, #8FC652 50%, #56A101 50%, #56A101);
    }

    &.btnClr_3 {
        background: -webkit-linear-gradient(left, #FF7940, #FF7940 50%, #FF3D00 50%, #FF3D00);
    }

    &.btnClr_4 {
        background: -webkit-linear-gradient(left, #FFC107, #FFC107 50%, #F9A400 50%, #F9A400);
    }
}