.facilities-main-list {
    padding: 0;

    .red-btn {
        background-color: #440F5A;
        color: #fff !important;
        margin-bottom: 25px;
        text-transform: none;
    }

    .facilities-con-wrp {
        background: #fff;
        border-radius: 24px;
        padding: 24px;
    }

}

.create-facilities {
    padding: 24px;
    background: #fff;
    border-radius: 24px;

    .form-upload-sec {
        label {
            width: 100%;
            height: 100%;
            border-radius: 0;
            border: 1px dashed #440F5A;
            display: block;
            padding: 35px;
            text-align: center;
        }

        svg {
            path {
                fill: #440F5A;
            }
        }
    }

    .rdw-editor-toolbar {
        visibility: visible !important;
        border: none;
    }

    .rdw-editor-main {
        border: 1px solid #B4AC9C;
        height: 100%;
        padding: 0 10px;
        border-radius: 4px;
        min-height: 275px;

        * {
            font-weight: inherit;
        }
    }
}

.create-facility {
    padding: 24px;
    background: #fff;
    border-radius: 24px;

    .form-upload-sec {
        label {
            width: 100%;
            height: 100%;
            border-radius: 0;
            border: 1px dashed #440F5A;
            display: block;
            padding: 35px;
            text-align: center;
        }

        svg {
            path {
                fill: #440F5A;
            }
        }
    }

    .rdw-editor-toolbar {
        visibility: visible !important;
        border: none;
    }

    .rdw-editor-main {
        border: 1px solid #B4AC9C;
        height: 100%;
        padding: 0 10px;
        border-radius: 4px;
        min-height: 275px;

        * {
            font-weight: inherit;
        }
    }
}

.view-facilities-wrp {
    background: #fff;
    padding: 24px;
    border-radius: 24px;

    .preview-img-sec {
        border: 1px solid #e4e4e4;
        border-radius: 10px;
        height: 100%;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    .facilities-con-sec {
        .basic-details {
            display: flex;

            p {
                width: max-content;
                font-size: 12px;
                margin: 0;
                margin-bottom: 5px;
                color: #4C483E;

                &:last-child {
                    margin-left: 15px;
                }
            }
        }

        h1,
        h2 {
            margin: 0;
            margin-bottom: 5px;
            display: block;
            color: #0F0A01;
        }

        p {
            color: #0F0A01;
        }
    }
}

.back-btn-wrp {
    button {
        font-size: 13px;
    }

    svg {
        width: 10px;
        height: 14px;
        margin-right: 4px;
    }
}

.stepper-sec-wrp {
    color: #212B36;

    svg {
        color: #6C6C6C;
    }

    .MuiStepLabel-label {
        font-weight: 500 !important;
    }

    .MuiStepLabel-root {
        width: max-content;
        margin: 0 auto;
        cursor: pointer;
    }

    .Mui-completed {
        color: #8032A1;
    }
}

.create_facility_slider {

    .swiper-button-prev,
    .swiper-button-next {
        position: relative;
        margin: 0 auto;
        margin-top: 35px;
        width: 45px;
        background: #440F5A;
        border-radius: 100%;
        height: 45px;
        border: 1px solid #440F5A;

        &.swiper-button-disabled {
            opacity: 1;
            color: #440F5A;
            background: #fff;
            border: 1px solid #440F5A;

            &::after {
                color: #440F5A;
            }
        }
    }

    .swiper-button-prev {
        float: left;
        margin-left: calc(45% - 30px);
    }

    .swiper-button-next {
        float: right;
        margin-right: calc(45% - 30px);
    }

    .swiper-button-prev::after,
    .swiper-button-next::after {
        font-size: 16px;
        color: #fff;
    }

    .swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal {
        display: none;
    }

    .template-preview-box {
        &.active {
            .selection-box {
                &::after {
                    content: "";
                    width: calc(100% + 10px);
                    height: calc(100% + 10px);
                    display: block;
                    border: 1px solid #FF7940;
                    position: absolute;
                    left: -5px;
                    top: -5px;
                    border-radius: 7px;
                }
            }
        }

        span {
            height: 215px;
            display: block;
            overflow-x: hidden;
            overflow-y: auto;
            border: 5px solid #070809;
            border-radius: 5px;
            padding: 0px 0px 0px 5px;
            box-shadow: 0px 0px 0px 10px inset #070809;
            border-bottom: 15px solid #070809;
            border-top: 14px solid #070809;
            position: relative;
            z-index: 1;
            cursor: pointer;

            &::-webkit-scrollbar {
                width: 5px;
            }

            &::-webkit-scrollbar-track {
                background: transparent;
            }

            &::-webkit-scrollbar-thumb {
                background: #8032A1;
                border-radius: 10px;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: #8032A1;
            }
        }

        p {
            color: #0F0A01;
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 0;
            margin-top: 10px;
            float: left;
            display: block;
        }
    }

    .clr-box {
        border: none;
        box-shadow: 0px 0px 4px 0px #e1e1e1;
    }


    .flex-box {
        display: flex;

        button,
        label {
            cursor: pointer;
        }

        &.banner-upload {
            gap: 24px;

            .form-upload-sec,
            .img-upload-wrp {
                flex: 1;
            }

            .img-upload-wrp {
                .each-img-blk {
                    width: 100%;
                    height: 145px;

                    .profile-img-sec {
                        display: block;
                        float: left;
                        width: 100%;
                        height: 100%;

                        span {
                            width: 100%;
                            height: 100%;
                            display: block;

                            img {
                                object-fit: cover;
                            }
                        }
                    }
                }
            }
        }

        &.favIcon-upload {
            gap: 10px;

            .logo-list {
                .each-img-blk {
                    height: 145px;
                    width: 100%;
                }
            }
        }

        &.each-service-box {
            gap: 24px;
            align-items: center;

            &:not(:last-child) {
                padding-bottom: 24px;
            }

            .service-icon {
                position: relative;

                .form-upload-sec {
                    label {
                        padding: 5px 20px;
                        width: 85px;
                        height: 70px;

                        p {
                            line-height: 1;
                        }

                        p+br {
                            display: none;
                        }

                        br+p {
                            display: none;
                        }
                    }
                }

                .form-upload-view {
                    width: 85px;
                    height: 70px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    left: 0;
                    top: 0;
                    background: #fff;
                    border: 1px dashed #440F5A;

                    span {
                        width: 40px;
                        height: 40px;

                        svg {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }


                .img-upload-wrp {
                    .each-img-blk {
                        width: 85px;
                        height: 70px;
                        border: 1px dashed #440F5A;
                    }
                }
            }

            .service-con {
                display: flex;
                width: 100%;
                gap: 24px;

                .app-text-input-container {
                    width: 100%;
                }

                &.teacher-content {
                    .app-text-input-container {

                        &:nth-child(1),
                        &:nth-child(2) {
                            flex: 1;
                        }

                        &:nth-child(3) {
                            flex: 2;
                        }
                    }
                }
            }

            .add-new-service {
                display: flex;
                padding-top: 10px;

                button {
                    background-color: #440F5A;
                    color: #FFF4EE;
                    border: none;

                    &.Mui-disabled {
                        background-color: #440F5A;
                        opacity: 0.8;
                        cursor: not-allowed;
                    }

                    svg {
                        path {
                            fill: #FFFFFF;
                        }
                    }
                }
            }
        }
    }

    .gallery-upload {
        .img-upload-wrp {
            padding-top: 24px;

            .each-img-blk {
                height: 140px !important;
                width: 140px !important;

                img {
                    object-fit: cover !important;
                    padding-right: 10px !important;
                }
            }
        }

        .each-gallery-box {
            padding-top: 20px;

            .flex-box {
                gap: 20px;
                flex-wrap: wrap;

                .each-img-blk {
                    width: calc(25% - 8px);
                    position: relative;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                        object-position: center;
                    }

                    &:hover {
                        button {
                            opacity: 1;
                            transition: 0.5s all;
                        }
                    }

                    button {
                        position: absolute;
                        right: 0;
                        top: 0;
                        z-index: 9;
                        background: #440F5A;
                        border-radius: 100%;
                        padding: 3px;
                        cursor: pointer;
                        border: none;
                        opacity: 0;
                        transition: 0.5s all;

                        svg {
                            width: 25px;
                            height: 25px;

                            path {
                                fill: #fff;
                            }
                        }
                    }
                }
            }
        }
    }

    .selectIconButton {
        border: 1px dashed #440F5A;
        width: 85px;
        height: 70px;
        background: transparent;
        cursor: pointer;

        p {
            margin: 0;
            color: #440F5A !important;
            font-size: 13px;
            font-weight: 700;
        }

        &.active::after {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
        }
    }

    .add-items-box {
        padding-top: 30px;
        // .each-added-items{

        // }
    }

    .facility-template-form-1 {
        .templateClrTheme_1 {
            background: -webkit-linear-gradient(to right, #FFFFFF 33%, #87D0B6 33%, #87D0B6 67%, #349471 67%);
            background: linear-gradient(to right, #FFFFFF 33%, #87D0B6 33%, #87D0B6 67%, #349471 67%);
        }

        .templateClrTheme_2 {
            background: -webkit-linear-gradient(to right, #FFFFFF 33%, #89A0D0 33%, #89A0D0 67%, #355394 67%);
            background: linear-gradient(to right, #FFFFFF 33%, #89A0D0 33%, #89A0D0 67%, #355394 67%);
        }

        .templateClrTheme_3 {
            background: -webkit-linear-gradient(to right, #FFFFFF 33%, #C5BACE 33%, #C5BACE 67%, #683C8F 67%);
            background: linear-gradient(to right, #FFFFFF 33%, #C5BACE 33%, #C5BACE 67%, #683C8F 67%);
        }

        .templateClrTheme_4 {
            background: -webkit-linear-gradient(to right, #FFFFFF 33%, #FFF0EC 33%, #FFF0EC 67%, #FE5D37 67%);
            background: linear-gradient(to right, #FFFFFF 33%, #FFF0EC 33%, #FFF0EC 67%, #FE5D37 67%);
        }
    }

    .facility-template-form-2 {
        .templateClrTheme_1 {
            background: -webkit-linear-gradient(to right, #FFFFFF 33%, #DB5A95 33%, #DB5A95 67%, #5D508F 67%);
            background: linear-gradient(to right, #FFFFFF 33%, #DB5A95 33%, #DB5A95 67%, #5D508F 67%);
        }

        .templateClrTheme_2 {
            background: -webkit-linear-gradient(to right, #FFFFFF 33%, #DB9861 33%, #DB9861 67%, #33828F 67%);
            background: linear-gradient(to right, #FFFFFF 33%, #DB9861 33%, #DB9861 67%, #33828F 67%);
        }

        .templateClrTheme_3 {
            background: -webkit-linear-gradient(to right, #FFFFFF 33%, #DBC497 33%, #DBC497 67%, #385C8F 67%);
            background: linear-gradient(to right, #FFFFFF 33%, #DBC497 33%, #DBC497 67%, #385C8F 67%);
        }

        .templateClrTheme_4 {
            background: -webkit-linear-gradient(to right, #FFFFFF 33%, #FE5D37 33%, #FE5D37 67%, #57509E 67%);
            background: linear-gradient(to right, #FFFFFF 33%, #FE5D37 33%, #FE5D37 67%, #57509E 67%);
        }
    }


}

.facility-website {
    label {
        cursor: pointer;
    }

    .headIdField {
        margin-top: 0 !important;

        input {
            height: 40px !important;
            padding: 0px 15px !important;
        }

        * {
            font-weight: 400;
        }
    }
    .icon-delete-btn{
        position: absolute;
        top: 0;
        right: 0;
        width: 25px;
        height: 25px;
        padding: 1px;
        background: #440F5A;
        border-radius: 100%;
        svg{
            width: 100%;
            height: 100%;
            path{
                fill: #fff;
            }
        }
    }
}