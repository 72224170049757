.news-main-wrp {
    padding: 0;

    .red-btn {
        background-color: #440F5A;
        color: #fff !important;
        margin-bottom: 25px;
        text-transform: none;
    }

    .news-con-wrp {
        background: #fff;
        border-radius: 24px;
        padding: 24px;
    }

}

.create-news {
    padding: 24px;
    background: #fff;
    border-radius: 24px;

    .form-upload-sec {
        label {
            width: 100%;
            height: 100%;
            border-radius: 0;
            border: 1px dashed #440F5A;
            display: block;
            padding: 35px;
            text-align: center;
        }

        svg {
            path {
                fill: #440F5A;
            }
        }
    }

    .rdw-editor-toolbar {
        visibility: visible !important;
        border: none;
    }

    .rdw-editor-main {
        border: 1px solid #B4AC9C;
        height: 100%;
        padding: 0 10px;
        border-radius: 4px;
        min-height: 275px;

        * {
            font-weight: inherit;
        }
    }
}

.view-news-wrp {
    background: #fff;
    padding: 24px;
    border-radius: 24px;

    .preview-img-sec {
        border: 1px solid #e4e4e4;
        border-radius: 10px;
        height: 100%;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    .news-con-sec {
        .basic-details {
            display: flex;

            p {
                width: max-content;
                font-size: 12px;
                margin: 0;
                margin-bottom: 5px;
                color: #4C483E;

                &:last-child {
                    margin-left: 15px;
                }
            }
        }

        h1,
        h2 {
            margin: 0;
            margin-bottom: 5px;
            display: block;
            color: #0F0A01;
        }

        p {
            color: #0F0A01;
        }
    }
}

.back-btn-wrp {
    button {
        font-size: 13px;
    }

    svg {
        width: 10px;
        height: 14px;
        margin-right: 4px;
    }
}