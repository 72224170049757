.auth-layout{
    max-width: 1980px;
    margin: 0 auto;
    .flex-box {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .form-sec-wrp{
            width: 100%;
            max-width: 375px;
            padding-top: 10px;
            padding-bottom: 55px;
            box-shadow: 0px 0px 4px 1.5px #e4e4e4;
            padding: 30px;
            margin: 50px 0;
            border-radius: 8px;
            
            .auth-logo{
                display: block;
                width: 80px;
                margin: 0 auto;
                padding-top: 25px;
            }
            .auth-form{
                max-width: 375px;
                margin: 0 auto;
                .form-tile{
                    padding-top: 20px;
                    padding-bottom: 25px;
                    h3{
                        font-size: 24px;
                    }
                    h4{
                        font-size: 16px;
                    }
                }
                .login-form{
                    .standard-btn{
                        margin-top: 20px;
                    }
                    .MuiFormControl-root .MuiOutlinedInput-notchedOutline {
                        border-color: #B4AC9C;
                    }
                    .form-group .custom-textfield label{
                        color: #5D5848;
                    }
                }
                .additional-btn{
                    p{
                        margin: 0;
                        margin-top: 10px;
                        font-size: 14px;
                        a{
                            color: #8032A1;
                            text-decoration: none;
                        }
                    }
                }
            }
        }
        .form-img-wrp{
            width: 50%;
            min-height: 100vh;
            max-height: 1090px;
            span{
                padding: 15px 0px 15px 0px;
                display: block;
                height: 100%;
                position: relative;
                img{
                    height: calc(100% - 30px);
                    object-fit: cover;
                    object-position: center;
                    position: absolute;
                    left: 0;
                    top: 15px;
                    border-radius: 25px;
                }
            }
        }
    }    
}

.Signup-wrp{

}