.accountTab-section {
    padding: 0;

    .red-btn {
        background-color: #440F5A;
        color: #fff !important;
        margin-bottom: 25px;
        text-transform: none;
    }

    .bulletin-con-wrp {
        background: #fff;
        border-radius: 24px;
        padding: 24px;
    }

}

.facility-details-wrp {
    .info-head{
        *{
            font-family: 'Andika', sans-serif;
        }
    }
    .each-details-sec.f-wrp {
        border-radius: 16px;
        border: 1px solid #CFC1D5;
        padding: 20px;
        height: 100%;
        .facility-basic {
            display: flex;
            gap: 30px;
            .facility-img {
                flex: 1;
                h4{
                    margin-bottom: 0;
                    margin-top: 10px;
                    display: block;
                    text-align: center;
                    font-weight: 700;
                    color: #8032A1;
                }
            }

            .facility-basic-details {
                flex: 1.25;

                ul {
                    li {
                        color: #0F0A01;
                        margin-bottom: 10px;
                        font-size: 14px;
                        b {
                            color: #0F0A01;
                            padding-right: 5px;
                        }
                        a{
                            max-width: 180px;
                            display: inline-block;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            vertical-align: middle;
                        }
                    }
                }
            }
        }
        .facility-chart-sec{
            display: flex;
            .facility-chart{
                svg{
                    path{
                        fill: rgb(82,0,116);
                    }
                }
            }
        }
        
    }
    .detail-sec-group{
        display: flex;
        gap: 16px;
        flex-wrap: wrap;

        .each-details-sec{
            width: calc(33.33% - 11px);
            margin-bottom: 16px;
            span{
                color: #8032A1;
                b{
                    margin-right: 5px;
                }
            }
        }
    }
}
.feature-main-list{
    .MuiSwitch-track{
        background-color: #8032A1 !important;
        opacity: 1 !important;
    }
    .app-grid-tool-bar,
    .MuiDataGrid-footerContainer{
        display: none;
    }
}


.facility-design {
    .img-upload-wrp {
        .upload-img {
            label {
                width: 100%;
                height: 100%;
                border-radius: 0;
                border: 1px dashed #440F5A;
                display: block;
                padding: 30px;
                text-align: center;
                svg {
                    width: 35px;
                    height: 35px;
                    path{
                        fill: #440F5A;
                    }
                }
            }
        }

        &.logo-list {
            .each-img-blk {
                width: 300px;
                display: block;
                height: 160px;
                border: none;
                border-radius: 0;
                margin: unset;
                float: left;

                img {
                    border-radius: 0;
                    object-fit: contain;
                }

                button {
                    background: #440F5A;
                    border-radius: 100%;
                    padding: 3px;

                    svg {
                        width: 25px;
                        height: 25px;

                        path {
                            fill: #fff;
                        }
                    }
                }
            }
        }
    }
}

.clr-box {
    border: 1px solid transparent;
    padding: 25px;
    border-radius: 7px;
    text-align: center;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    margin-top: 5px;
    position: relative;
    margin-bottom: 5px;

    span {
        border-radius: 5px;
        background: rgba(255, 255, 255, 0.75);
        color: #FF7940;
        padding: 3px 7px;
        opacity: 0;
        transition: 0.5s all;
    }

    &:hover span {
        opacity: 1;
        transition: 0.5s all;
    }

    &:active,
    &:focus,
    &:hover,
    &.selected {
        &::after {
            content: '';
            width: calc(100% + 10px);
            height: calc(100% + 10px);
            display: block;
            border: 1px solid #FF7940;
            position: absolute;
            left: -5px;
            top: -5px;
            border-radius: 7px;
        }
    }

    &.light {
        background: -webkit-linear-gradient(left, #fff, #fff 50%, #F5F0E7 50%, #F5F0E7);
    }

    &.dark {
        background: -webkit-linear-gradient(left, #0F0A01, #0F0A01 50%, #211E1D 50%, #211E1D);
    }

    &.btnClr_1 {
        background: -webkit-linear-gradient(left, #638EFF, #638EFF 50%, #4075FF 50%, #4075FF);
    }

    &.btnClr_2 {
        background: -webkit-linear-gradient(left, #8FC652, #8FC652 50%, #56A101 50%, #56A101);
    }

    &.btnClr_3 {
        background: -webkit-linear-gradient(left, #FF7940, #FF7940 50%, #FF3D00 50%, #FF3D00);
    }

    &.btnClr_4 {
        background: -webkit-linear-gradient(left, #FFC107, #FFC107 50%, #F9A400 50%, #F9A400);
    }
}