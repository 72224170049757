.home-main-wrp {
    background-color: #0f0a01;

    .animated-banner-wrp.f-wrp {
        display: none;

        img {
            display: none;
        }
    }

    .abt-con-wrp {
        background-image: url('../../assets/images/abtBG_main.png');
        background-position: right top;
        background-size: 100%;
        background-repeat: no-repeat;
        padding-top: 75px;
        background-color: #8ae109;

        @media screen and (max-width: 767px) {
            & {
                background-size: auto 68%;
            }
        }

        &::after {
            content: '';
            background-image: url('../../assets/images/abtBG_bottmSub.png');
            background-position: right top;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            position: absolute;
            height: 65px;
            width: 100%;
            left: 0;
            bottom: -64px;
            z-index: 99;

            @media screen and (max-width: 767px) {
                & {
                    background-size: 100% 40%;
                }
            }
        }

        .line-sec {
            display: block;
            width: 100%;
            position: absolute;
            float: left;
            height: 100%;
            overflow: hidden;

            .abt-sec-line_1,
            .abt-sec-line_2 {
                position: absolute;
                left: -25px;
                top: 50%;
                width: calc(100% + 50px);

                @media screen and (max-width: 767px) {
                    & {
                        width: calc(275% + 50px);
                    }
                }
            }

            .abt-sec-line_1 {
                margin-top: -250px;
                transform: rotate(-6.161deg);

                @media screen and (max-width: 767px) {
                    & {
                        margin-top: -130px;
                    }
                }
            }

            .abt-sec-line_2 {
                margin-top: -25px;
                transform: rotate(8.235deg);

                @media screen and (max-width: 767px) {
                    & {
                        margin-top: 50px;
                    }
                }
            }
        }

        .abt-img-sec {
            position: relative;
            width: 100%;
            display: table;

            @media screen and (max-width: 767px) {
                & {
                    display: none;
                }
            }

            img {
                position: absolute;
                width: 200px;
                top: -55px;
                display: block;
                z-index: 9999999;
                max-width: 300px;
                right: 0;
            }
        }

        .abt-header.f-wrp {
            text-align: center;
            max-width: 660px;
            margin: 0 auto;
            float: none;
            display: table;
            padding-bottom: 50px;

            h2 {
                color: #5D5848;
                font-size: 36px;
                font-family: 'Andika', sans-serif;
                font-style: normal;
                font-weight: 700;
                line-height: 124.633%;
            }

            p {
                color: #5D5848;
                text-align: center;
                font-size: 16px;
                font-family: Roboto;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }

        .content-sec-wrp {
            .row {
                justify-content: center;

                @media screen and (max-width: 767px) {
                    & {
                        display: none;
                    }
                }
            }

            @media screen and (min-width: 767px) {
                & .content-sec-mob {
                    display: none;
                }
            }

            @media screen and (max-width: 767px) {
                & .content-sec-mob {

                    .swiper-button-next:after,
                    .swiper-button-prev:after {
                        color: #6eb307;
                        font-size: 20px;
                    }
                }
            }

            .each-abt-sec {
                background: rgba(255, 243, 229, 0.75);
                border-radius: 100%;
                text-align: center;
                width: 235px;
                height: 235px;
                padding: 10px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin: 0 auto;
                float: none;
                margin-bottom: 65px;

                @media screen and (max-width: 1200px) {
                    & {
                        width: 210px;
                        height: 210px;
                    }
                }

                * {
                    color: #fff;
                }

                &.box1 {
                    background: #D7252A;
                }

                &.box2 {
                    background: #F26335;
                }

                &.box3 {
                    background: #F4AA28;
                }

                &.box4 {
                    background: #30B44B;
                }

                &.box5 {
                    background: #38A1A5;
                }

                &.box6 {
                    background: #2C2F76;
                }

                &.box7 {
                    background: #42409A;
                }

                h3 {
                    font-family: 'Andika', sans-serif;
                    font-style: normal;
                    font-weight: 700;
                    margin-top: 5px;
                    margin-bottom: 5px;
                }

                .abt-icon {
                    width: 55px;
                    display: block;
                    height: 55px;
                    margin: 0 auto;

                    @media screen and (max-width: 1200px) {
                        & {
                            width: 40px;
                            height: 40px;
                        }
                    }
                }

                p {
                    display: block;
                    margin: 0;
                    font-size: 14px;

                    @media screen and (max-width: 1200px) {
                        & {
                            font-size: 12px;
                            width: 90%;
                            margin: 0 auto;
                        }
                    }
                }
            }
        }
    }

    .team-con-wrp {
        padding-bottom: 85px;
        background: #0f0a01;

        @media screen and (max-width: 991px) {
            & {
                padding-bottom: 0px;
            }
        }

        @media screen and (max-width: 767px) {
            .team-img-sec {
                img {
                    min-height: 250px;
                    object-fit: cover;
                }
            }
        }

        .team-con-sec {
            background-image: url('../../assets/images/teamTailBG.png');
            background-position: right top;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            position: absolute;
            left: 0;
            bottom: 0px;

            @media screen and (max-width: 991px) {
                & {
                    background-image: url('../../assets/images/teamTailBGMob.png');
                    position: relative;
                    left: 0;
                    bottom: 15px;
                }
            }

            span {
                width: 85%;
                display: block;
                float: right;
                padding: 30px 0px;

                @media screen and (max-width: 991px) {
                    & {
                        width: 100%;
                    }
                }
            }

            h2 {
                text-align: right;
                font-size: 46px;
                font-family: Roboto;
                font-style: normal;
                font-weight: 600;
                color: #FFF9F5;
                margin: 0;
                line-height: 1;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                svg {
                    width: 45px;
                    height: 45px;
                    margin-left: 10px;
                }

                @media screen and (max-width: 991px) {
                    & {
                        flex-direction: column;
                        text-align: center;

                        svg {
                            margin-left: 0;
                            margin-top: 10px;
                        }
                    }
                }

                @media screen and (max-width: 991px) {
                    & {
                        font-size: 32px;
                    }
                }
            }
        }

        .team-lft-objt {
            position: absolute;
            left: 0;
            bottom: 15px;
            width: 30%;
            height: 100%;
            display: flex;
            align-items: flex-end;
            z-index: 999;

            .each-objt {
                z-index: 9;
                height: 100%;
                width: 100%;

                // &.objtBG{
                //     position: absolute;
                //     left: 0;
                //     bottom: 15px;
                //     span{
                //         height: 100%;
                //         display: block;
                //         img{
                //             display: none;
                //             height: 100%;
                //             object-fit: contain;
                //             object-position: bottom;
                //         }
                //     }
                // }
                &.parentBG {
                    width: 100%;
                    position: relative;
                    background: url('../../assets/images/Union.png');
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: bottom left;
                    top: 25px;

                    span {
                        display: block;
                        width: 100%;
                        height: 75%;

                        img {
                            height: 100%;
                            object-fit: contain;
                            width: 100%;
                        }
                    }
                }
            }

            @media screen and (max-width: 991px) {
                & {
                    display: none;
                }
            }
        }
    }

    .learn-more-wrp {
        background-color: #0f0a01;

        .containe {
            max-width: 1500px;
            display: block;
            margin: 0 auto;
        }

        .learn-more-sec {
            padding: 120px 0;
            display: flex;

            @media screen and (max-width: 991px) {
                & {
                    flex-wrap: wrap;
                    justify-content: center;
                    padding: 0 0 75px;
                }
            }

            .learn-con-wrp {
                // max-width: 50vw;
                // min-height: 40vw;
                width: 50vw;
                max-width: 660px;
                height: 500px;
                min-width: 660px;
                padding: 70px 0px 0px 55px;
                background-image: url('../../assets/images/learnMoreBG.png');
                background-size: contain;
                background-repeat: no-repeat;

                @media screen and (min-width: 991px) {
                    .bg-tmp {
                        display: none;
                    }
                }

                @media screen and (max-width: 991px) {
                    & {
                        width: 100%;
                        max-width: 100%;
                        height: 100%;
                        min-width: 100%;
                        padding: 0;
                        background: none;
                    }
                }

                .content-wrp {
                    width: 495px;
                    padding: 50px 0px 0 0px;
                    display: table;
                    margin: 0 auto;
                    float: none;

                    @media screen and (max-width: 991px) {
                        & {
                            width: 100%;
                            display: block;
                            padding: 50px 15px 0 15px;
                            background: #f9efe8;
                            text-align: center;
                        }
                    }

                    @media screen and (min-width: 991px) {
                        & {
                            .learn-con-slider {
                                display: none;
                            }
                        }
                    }

                    @media screen and (max-width: 991px) {
                        & {
                            .learn-con-slider {
                                // display: none;
                                .swiper-slide{
                                    width: auto !important;
                                    ul{
                                        li{
                                            flex-direction: column;
                                            min-width: 100px;
                                            span{
                                                margin-right: 0;
                                            }
                                            svg{
                                                width: 35px;
                                                height: 35px;
                                            }
                                            p{
                                                font-size: 14px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    @media screen and (max-width: 991px) {
                        & {
                            .learn-con-slider {
                                ul {
                                    display: block;

                                    li {
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }

                    h1 {
                        font-family: 'Andika', sans-serif;
                        color: #257D28;
                        font-weight: 700;
                        margin-top: 0;
                        margin-bottom: 10px;
                    }

                    p {
                        font-size: 14px;
                        margin: 0;
                    }

                    ul {
                        display: flex;
                        flex-wrap: wrap;
                        padding-top: 20px;

                        li {
                            display: flex;
                            align-items: center;
                            width: 50%;
                            margin-bottom: 5px;

                            span {
                                margin-right: 10px;
                            }

                            p {
                                margin: 0;
                                font-size: 13px;
                            }
                        }

                        @media screen and (max-width: 991px) {
                            & {
                                display: none;
                            }
                        }
                    }
                }

            }

            .learn-img-wrp {
                width: 40vw;
                display: flex;
                position: relative;
                height: 40vw;
                max-width: 800px;
                max-height: 800px;

                @media screen and (max-width: 1110px) {
                    & {
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        width: auto;
                    }
                }

                @media screen and (max-width: 991px) {
                    & {
                        position: relative;
                        height: auto;
                        max-width: 540px;
                        margin: 0 auto;
                        justify-content: flex-end;
                        top: -50px;
                    }
                }

                .main-img {
                    width: 100%;
                    position: relative;
                    padding-bottom: 20%;

                    @media screen and (max-width: 991px) {
                        & {
                            width: 75%;
                        }
                    }

                    &>img {
                        height: 100%;
                        object-fit: contain;
                    }

                    .horse-img {
                        position: absolute;
                        bottom: 0%;
                        right: -15%;

                        img {
                            width: 35vw !important;
                            max-width: 700px;

                            @media screen and (max-width: 1250px) {
                                & {
                                    width: 70% !important;
                                    float: right;
                                }
                            }
                        }

                        @media screen and (max-width: 1250px) {
                            & {
                                right: 0;
                            }
                        }
                    }
                }

                .sub-img {
                    position: absolute;
                    left: -25%;
                    // width: 23vw;
                    bottom: 10%;
                    width: 50%;
                    max-width: 400px;

                    @media screen and (max-width: 991px) {
                        & {
                            left: 1%;
                            bottom: 10%;
                            width: 45%;
                        }
                    }
                }
            }
        }
    }

    .staff-sec-wrp {
        background-image: url('../../assets/images/staff_assets/cloud4.png'), url('../../assets/images/staff_assets/staffMainBG.png');
        background-size: 15%, 100% 100%;
        background-repeat: no-repeat;
        background-position: right 99%, center;
        padding: 150px 0 130px;
        margin-bottom: 25px;

        @media screen and (max-width: 1400px) {
            & {
                background-size: 15%, cover;
            }
        }

        @media screen and (max-width: 991px) {
            & {
                background-image: url('../../assets/images/staff_assets/cloud4.png'), url('../../assets/images/staff_assets/staffMainBGMob.png');
                background-size: 15%, 100% 100%;
                padding-bottom: 0;
                padding-top: 100px;
            }
        }

        .clouds-float-wrp.f-wrp {
            position: absolute;
            left: 0;
            height: 100%;
            width: 100%;
            top: 0;

            span {
                width: 100%;
                display: block;
                position: absolute;
                overflow: hidden;

                &:nth-child(1) {
                    top: 20%;

                    img {
                        position: relative;
                        width: 200px;
                        animation: cloudAnimation 15s linear infinite;
                    }
                }

                &:nth-child(2) {
                    top: 40%;

                    img {
                        position: relative;
                        width: 200px;
                        animation: cloudAnimation 20s linear infinite;
                    }
                }

                &:nth-child(3) {
                    top: 72%;

                    img {
                        position: relative;
                        width: 150px;
                        animation: cloudAnimation 25s linear infinite;
                    }
                }
            }

            @keyframes cloudAnimation {
                0% {
                    right: 10%;
                }

                100% {
                    right: -110%;
                }
            }
        }

        .lady-img {
            position: absolute;
            right: 35px;
            bottom: 10px;
            width: 145px;

            @media screen and (max-width: 991px) {
                & {
                    position: relative;
                    right: 35px;
                    display: block;
                    height: auto;
                    bottom: 2px;
                    float: right;
                }
            }
        }

        .staff-sec-container {
            display: flex;
            align-items: flex-end;
            flex-wrap: wrap;

            @media screen and (max-width: 991px) {
                & {
                    flex-direction: column;
                    align-items: center;
                    padding-top: 200px;
                }
            }

            .img-sec-wrp {
                flex: 1;

                span {
                    max-width: 500px;
                    width: 100%;
                    display: block;
                    margin: 0 auto;

                    @media screen and (max-width: 991px) {
                        & {
                            max-width: 500px;
                            display: block;
                            margin: 0 auto;
                            width: 100%;
                            height: 450px;

                            img {
                                object-fit: contain;
                                width: 100%;
                                height: 100%;
                                object-position: center;
                            }
                        }
                    }
                }
            }

            .con-sec-wrp {
                padding-bottom: 5%;
                padding-left: 25px;
                max-width: 650px;
                width: 100%;

                @media screen and (max-width: 1400px) {
                    & {
                        flex: 1;
                    }
                }
                @media screen and (max-width: 575px) {
                    & {
                        padding-left: 0px;
                    }
                }

                .staff-con-img {
                    display: flex;
                    max-width: 385px;
                    position: relative;
                    animation: angelAnimation 5s linear infinite;

                    .baloon-img {
                        position: relative;
                        top: -50px;
                        left: 25px;
                    }

                    .angel2-img {
                        padding-top: 45px;
                    }

                    @keyframes angelAnimation {
                        0% {
                            top: 0;
                        }

                        50% {
                            top: 20px;
                        }

                        100% {
                            top: 0;
                        }
                    }

                    @media screen and (max-width: 991px) {
                        & {
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            margin: 0 auto;
                        }
                    }
                }

                h1 {
                    color: #FFF500;
                    font-family: 'Andika', sans-serif;
                    margin: 0;
                }

                p {
                    color: #0F0A01;

                    b {
                        color: #FFF500;
                    }

                    &:nth-of-type(2) {
                        width: calc(100% - 70px);
                        @media screen and (max-width: 575px) {
                            & {
                                width: 100%;
                            }
                        }
                    }
                }

                .pln-btn {
                    display: flex;
                    align-items: center;
                    color: #440F5A;
                    text-decoration: underline
                }
            }
        }
    }

    .press-sec-wrp {
        .press-header {
            text-align: center;
            margin: 30px 0 60px;

            .press-icon {
                width: 65px;
                margin: 0 auto;
                display: block;
                margin-bottom: 10px;
            }

            h1,
            p {
                margin: 0;
                color: #fff;
            }

            h1 {
                font-family: 'Andika', sans-serif;
            }
        }

        .press-content {
            padding-bottom: 100px;
            .press-content-slider{
                display: none;
            }
            @media screen and (max-width: 991px) {
                & {
                    padding-bottom: 50px;
                }
            }
            @media screen and (max-width: 767px) {
                & .row {
                   display: none;
                }
                .press-content-slider{
                    display: block;
                }
            }

            .row>div:nth-child(odd) {
                a.f-wrp {
                    float: left;
                }
            }

            .row>div:nth-child(even) {
                a.f-wrp {
                    float: right;
                }
            }

            a {
                max-width: 500px;

                .basic-news-blk {
                    .press-basic {
                        display: flex;
                        align-items: center;
                        color: #fff;

                        img {
                            width: 20px;
                            margin-right: 10px;
                        }

                        margin-bottom: 10px;
                    }

                    .press-title {
                        margin: 0;
                        color: #440F5A;
                        text-decoration: underline;
                        background: transparent;
                        border: none;
                        font-size: 18.72px;
                        padding: 0;
                        cursor: pointer;
                    }

                    p {
                        color: #fff;
                        margin-bottom: 5px;
                    }

                    .date {
                        color: #B4AC9C;
                        font-size: 13px;
                    }
                }

                .press-img {
                    display: table;
                    // height: 255px;
                    width: 100%;
                    border-radius: 15px;
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                    }
                }
            }
        }

        .crp-block-wrp {
            padding-top: 100px;

            @media screen and (max-width: 991px) {
                & {
                    padding-top: 30px;
                }
            }

            .crp-header {
                display: flex;
                align-items: center;
                justify-content: center;

                @media screen and (max-width: 991px) {
                    & {
                        span {
                            display: none;
                        }
                    }
                }

                h1 {
                    margin: 0;
                    font-family: 'Andika', sans-serif;
                    color: #fff;
                    font-weight: 700;
                    font-size: 42px;
                    white-space: nowrap;
                    padding-left: 20px;
                    padding-right: 30px;
                    position: relative;

                    @media screen and (max-width: 991px) {
                        & {
                            white-space: break-spaces;
                            text-align: center;
                        }
                    }

                    @media screen and (max-width: 767px) {
                        & {
                            font-size: 36px;
                        }
                    }

                    &::after {
                        content: '';
                        width: calc(100% - 50px);
                        height: 50px;
                        position: absolute;
                        left: 0;
                        right: 0;
                        margin: 0 auto;
                        background-image: url('./../../assets/images/CPR/h1Aftr.png');
                        background-position: center;
                        background-size: contain;
                        background-repeat: no-repeat;
                        top: 100%;
                    }
                }
            }

            .crp-con-blk {
                padding-top: 25px;
                background-image: url('../../assets/images/CPR/girls.png');
                background-position: right bottom;
                background-size: 510px;
                background-repeat: no-repeat;
                padding-bottom: 55px;

                @media screen and (max-width: 1200px) {
                    & {
                        background-size: 43%;
                    }
                }

                @media screen and (max-width: 991px) {
                    & {
                        background-image: none;
                    }
                }

                h1 {
                    display: flex;
                    align-items: center;
                    color: #fff;
                    font-family: 'Andika', sans-serif;
                    font-size: 42px;
                    position: relative;

                    @media screen and (max-width: 991px) {
                        & {
                            align-items: flex-end;
                        }
                    }

                    @media screen and (max-width: 767px) {
                        & {
                            display: block;
                            width: 100%;
                            font-size: 38px;
                        }
                    }

                    img {
                        width: 10px;
                        margin-left: 10px;

                        @media screen and (max-width: 991px) {
                            & {
                                display: none;
                            }
                        }
                    }

                    .slide-txt {
                        font-family: 'Architects Daughter', cursive;
                        color: #FBE09A;
                        position: relative;
                        right: -95px;
                        transform: rotate(-9.578deg);

                        @media screen and (max-width: 1110px) {
                            & {
                                right: -10px;
                            }
                        }

                        @media screen and (max-width: 991px) {
                            & {
                                right: 35px;
                            }
                        }

                        @media screen and (max-width: 767px) {
                            & {
                                position: relative;
                                right: 0;
                                float: right;
                            }
                        }
                    }
                }

                &>h1::before,
                &>h1::after {
                    background-size: contain;
                    background-repeat: no-repeat;
                    position: absolute;

                    @media screen and (max-width: 991px) {
                        & {
                            display: none;
                        }
                    }
                }

                &>h1::before {
                    content: '';
                    background-image: url('../../assets/images/CPR/head-befr.png');
                    width: 80px;
                    height: 40px;
                    top: -30px;
                    left: 10px;
                }

                &>h1::after {
                    content: '';
                    background-image: url('../../assets/images/CPR/head-aftr.png');
                    width: 40px;
                    height: 40px;
                    bottom: -10px;
                    left: -32px;
                }

                p {
                    color: #fff;
                    max-width: 680px;
                    margin: 0;
                }

                .rate-box.f-wrp {
                    display: flex;
                    align-items: center;

                    h2 {
                        color: #fff;
                        font-size: 32px;
                        max-width: 185px;
                        margin-right: 60px;

                        @media screen and (max-width: 767px) {
                            & {
                                max-width: 100%;
                                margin-right: 0;
                                font-size: 36px;
                                font-weight: 400;
                            }
                        }
                    }

                    .rate {
                        h1 {
                            color: #0F0A01;
                            font-family: 'Architects Daughter', cursive;
                            display: block;
                            background-image: url('../../assets/images/CPR/chalk.png');
                            padding-left: 35px;
                            padding-right: 35px;
                            background-position: center;
                            background-size: contain;
                            background-repeat: no-repeat;
                            font-size: 50px;
                            margin: 0;

                            @media screen and (max-width: 767px) {
                                & {
                                    font-size: 30vw;
                                }
                            }
                        }

                        h5 {
                            color: #fff;
                            font-family: 'Architects Daughter', cursive;
                            margin: 0;
                            text-align: center;
                            font-size: 22px;
                            font-weight: 400;

                            span {
                                display: block;
                                font-size: 14px;
                            }
                        }
                    }

                    @media screen and (max-width: 767px) {
                        & {
                            flex-direction: column;
                        }
                    }
                }

                .into-box {
                    display: block;
                    float: left;
                    max-width: 625px;
                    text-align: right;

                    @media screen and (max-width: 767px) {
                        & {
                            float: right;
                        }
                    }

                    h1 {
                        font-family: 'Architects Daughter', cursive;
                        margin: 0;
                        display: block;
                        text-transform: uppercase;
                        color: #FFB69A;

                        @media screen and (max-width: 767px) {
                            & {
                                font-size: 24px;
                            }
                        }
                    }

                    p {
                        text-transform: lowercase;
                        font-size: 28px;
                        color: #FFF4EE;

                        @media screen and (max-width: 767px) {
                            & {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }
    }

    .contct-sec-wrp {
        background-image: url('../../assets/images/contact/cntactBgGreen.png');
        background-position: center;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin-top: 250px;

        @media screen and (max-width: 991px) {
            & {
                background-image: url('../../assets/images/contact/cntactBgGreenMob.png');
                margin-top: 50px;
            }
        }

        .contact-sec-container {
            display: flex;
            width: 100%;
            padding: 20px 40px;
            float: none;
            margin: 0 auto;
            margin-top: 200px;
            margin-bottom: 175px;
            background-image: url('../../assets/images/contact/cntactBgWht.png');
            background-position: center;
            background-size: 100% 100%;
            background-repeat: no-repeat;

            &::before {
                content: '';
                background-image: url('../../assets/images/contact/child.png');
                position: absolute;
                left: 20%;
                top: -80%;
                width: 180px;
                height: 375px;
                background-size: contain;
                background-repeat: no-repeat;

                @media screen and (max-width: 991px) {
                    & {
                        display: none;
                    }
                }
            }

            &::after {
                content: '';
                background-image: url('../../assets/images/contact/Child_illu.png');
                position: absolute;
                right: 0;
                bottom: -80px;
                width: 190px;
                height: 400px;
                background-size: contain;
                background-repeat: no-repeat;

                @media screen and (max-width: 991px) {
                    & {
                        display: none;
                    }
                }
            }

            @media screen and (max-width: 991px) {
                & {
                    margin-top: 25px;
                    margin-bottom: 25px;
                    padding: 20px 0px;
                }
            }

            .contct-img-sec {
                width: 300px;
                margin-left: 50px;
                margin-right: 50px;

                @media screen and (max-width: 1200px) {
                    & {
                        margin-left: 0px;
                        margin-right: 0px;
                        width: 235px;
                    }
                }

                @media screen and (max-width: 991px) {
                    & {
                        display: none;
                    }
                }

                .press-icon {
                    width: 100%;
                    height: 100%;
                    display: block;

                    img {
                        height: 100%;
                        object-fit: contain;
                    }
                }
            }

            .contct-con-sec {
                width: 100%;

                @media screen and (max-width: 1200px) {
                    & {
                        padding-top: 50px;
                        padding-bottom: 50px;
                    }
                }

                .flex-box {
                    display: flex;
                    align-items: center;

                    .conct-main-con {
                        width: 425px;

                        @media screen and (max-width: 1200px) {
                            & {
                                width: 100%;
                            }
                        }

                        h1 {
                            margin: 0;
                            color: #8032A1;
                            font-weight: 700;
                            font-family: 'Andika', sans-serif;

                            @media screen and (max-width: 991px) {
                                & {
                                    text-align: center;
                                }
                            }
                        }

                        p {
                            font-size: 14px;
                            width: 350px;

                            @media screen and (max-width: 991px) {
                                & {
                                    text-align: center;
                                    width: 100%;
                                }
                            }
                        }

                        ul {
                            li {
                                display: flex;
                                align-items: center;

                                &:not(:last-child) {
                                    margin-bottom: 15px;
                                }

                                span {
                                    width: 20px;
                                    height: 20px;
                                    display: block;

                                    svg {
                                        width: 16px;
                                        height: 16px;
                                    }
                                }

                                p {
                                    margin: 0;
                                    font-size: 14px;
                                    color: #0F0A01;
                                    font-weight: 500;
                                    width: max-content;

                                }

                                div {
                                    display: flex;
                                    position: relative;

                                    &:not(:last-child) {
                                        padding-right: 5px;

                                        &::after {
                                            content: '';
                                            width: 1px;
                                            height: 100%;
                                            background: #0F0A01;
                                            position: absolute;
                                            right: 0;
                                            top: 0;
                                        }
                                    }
                                }
                            }

                            @media screen and (max-width: 991px) {
                                & {
                                    padding-top: 50px;
                                }
                            }
                        }
                    }

                    .wall-painting {
                        position: relative;

                        @media screen and (max-width: 991px) {
                            & {
                                display: none;
                            }
                        }

                        .wall-paint {
                            width: 330px;
                            display: block;

                            @media screen and (max-width: 1200px) {
                                & {
                                    width: 225px;
                                }
                            }
                        }
                    }
                }

                .social-list {
                    display: flex;
                    padding-top: 10px;
                    padding-bottom: 25px;

                    li {
                        margin-right: 10px;

                        @media screen and (max-width: 991px) {
                            &:not(:last-child) {
                                margin-bottom: 10px;
                            }
                        }

                        @media screen and (max-width: 767px) {
                            & {
                                margin-right: 0px;
                            }
                        }
                    }

                    a {
                        display: flex;
                        align-items: center;
                        height: 50px;
                        padding: 0px 25px;
                        border-radius: 50px;
                        border: 1px solid #548A12;

                        @media screen and (max-width: 767px) {
                            & {
                                justify-content: center;
                            }
                        }

                        &:not(:last-child) {
                            margin-right: 20px;
                        }

                        span {
                            width: 20px;
                            display: block;
                            margin-right: 7px;
                        }

                        &.fb {
                            color: #1877F2;
                        }

                        &.whtsap {
                            color: #257D28;
                        }

                        &.insta {
                            color: #FF3D55;
                        }

                        p {
                            font-weight: 700;
                            margin: 0;
                        }
                    }

                    @media screen and (max-width: 991px) {
                        & {
                            padding-bottom: 0;
                            flex-wrap: wrap;
                            margin-top: 35px;
                            justify-content: center;
                        }
                    }

                    @media screen and (max-width: 767px) {
                        & {
                            flex-direction: column;
                        }
                    }
                }
            }
        }
    }

    .video-sec-wrp {
        // min-height: 770px;
        margin: 125px 0;

        .max-width-wrp {
            display: table;
            margin: 0 auto;
            position: relative;
            width: 100%;
            height: 100%;
            max-width: 1500px;
        }

        .video-sec-bg {
            // position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;

            img {
                height: 100%;
                object-position: center;
                object-fit: contain;
            }
        }

        .video-btn-blk {
            text-align: center;
            max-width: 350px;
            margin: 0 auto;
            position: absolute;
            right: 20%;
            bottom: 185px;

            @media screen and (max-width: 767px) {
                & {
                    bottom: 0;
                    right: 0;
                    left: 0;
                }
            }

            .video-icon {
                width: 70px;
                display: block;
                margin: 0 auto;
            }

            .blk-btn {
                background-color: #440F5A;
                color: #FFF4EE;
                display: inline-block;
                padding: 0 75px;
                height: 50px;
                line-height: 50px;
                border-radius: 50px;
                font-weight: 700;
            }
        }
    }

    .enroll-sec-wrp {
        background-image: url('../../assets/images/enrollment-bg.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 745px;

        @media screen and (max-width: 991px) {
            & {
                height: 100%;
                background-size: cover;
            }
        }

        .max-width-wrp {
            display: table;
            margin: 0 auto;
            position: relative;
            width: 100%;
            height: 100%;
            max-width: 1500px;
            height: 745px;

            @media screen and (max-width: 991px) {
                & {
                    height: 100%;
                }
            }

            &::after {
                content: '';
                background-image: url('../../assets/images/enrollmentBGImg.png');
                width: calc(50% + 60px);
                height: calc(100% - 15px);
                display: block;
                position: absolute;
                left: 0;
                top: 20px;
                background-size: auto 100%;
                background-position: right bottom;
                background-repeat: no-repeat;

                @media screen and (max-width: 991px) {
                    & {
                        display: none;
                    }
                }
            }
        }

        .castle {
            width: 255px;
            display: block;
            position: absolute;
            right: 0;
            top: -100px;

            @media screen and (max-width: 991px) {
                & {
                    display: none;
                }
            }

            img {
                position: relative;
                animation: angelAnimation 5s linear infinite;
            }

            @keyframes angelAnimation {
                0% {
                    top: 0;
                }

                50% {
                    top: 20px;
                }

                100% {
                    top: 0;
                }
            }
        }

        .enroll-sec-continer {
            max-width: 500px;
            float: right;
            padding-top: 85px;
            display: flex;
            flex-direction: column;
            height: 100%;
            z-index: 9;

            * {
                color: #fff;
            }

            h1 {
                font-family: 'Andika', sans-serif;

                @media screen and (max-width: 991px) {
                    & {
                        text-align: center;
                    }
                }
            }

            P {
                font-size: 14px;
            }

            span {
                font-size: 12px;
                word-break: break-all;
            }

            .blk-btn {
                border-radius: 50px;
                background: #440F5A;
                display: flex;
                height: 50px;
                padding: 5px 37px;
                justify-content: center;
                align-items: center;
                margin-top: 70px;
            }

            @media screen and (max-width: 991px) {
                & {
                    margin: 0 auto;
                    float: none;
                    padding-bottom: 125px;
                }
            }
        }
    }

    .payment-sec-wrp {
        background-image: url('../../assets/images/paymentBGImg.png'), url('../../assets/images/paymentBG.png');
        background-position: right 40px, center;
        background-repeat: no-repeat;
        background-size: 48%, 100% 100%;
        min-height: 600px;
        margin-top: 150px;
        overflow: hidden;

        @media screen and (max-width: 991px) {
            & .container {
                max-width: 100%;
            }

            & {
                background-size: 48%, cover;
                background-position: right center, center;
            }
        }

        @media screen and (max-width: 767px) {
            & {
                background-size: 0, cover;
            }
            & .container {
                padding-left: 0;
                padding-right: 0;
            }
        }
        @media (max-width: 575px) {
            margin-top: 75px;
        }
        .payment-img-sec {
            display: none;
            position: relative;
            @media screen and (max-width: 767px) {
                & {
                    display: block;
                }
                &::after {
                    content: '';
                    background-image: url('../../assets/images/Decoration.png');
                    background-position: right top;
                    background-size: contain;
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 20px;
                    height: 100%;
                    background-repeat: no-repeat;
                }
            }
        }

        &::after {
            content: '';
            background-image: url('../../assets/images/Decoration.png');
            background-position: right top;
            background-size: contain;
            position: absolute;
            right: 0;
            top: 0;
            width: 20px;
            height: 100%;
            @media screen and (max-width: 767px) {
                &{
                    display: none;
                }
            }
        }

        .payment-sec-container {
            display: block;
            float: left;
            padding-top: 125px;
            padding-bottom: 125px;

            @media screen and (max-width: 1200px) {
                & {
                    width: 50%;
                }
            }

            @media screen and (max-width: 991px) {
                width: 49%;
            }

            @media screen and (max-width: 767px) {
                & {
                    padding-top: 0px;
                    padding-bottom: 50px;
                    width: 100%;
                    padding-left: 15px;
                    padding-right: 15px;
                }
            }

            .payment-sec-con {
                max-width: 500px;

                @media screen and (max-width: 991px) {
                    max-width: 100%;
                }

                * {
                    color: #0F0A01;
                }

                h1 {
                    color: #2C89DD;
                    font-family: 'Andika', sans-serif;
                    font-size: 42px;
                    font-weight: 700;
                }

                p {
                    span {
                        font-size: 12px;
                        display: block;
                    }
                }

                .blk-btn {
                    display: block;
                    background: #2A9D8F;
                    height: 50px;
                    line-height: 50px;
                    text-align: center;
                    color: #fff;
                    font-weight: 700;
                    border-radius: 50px;
                    margin-top: 30px;
                }
            }
        }
    }

    .tuition-sec-wrp {

        .max-width-wrp {
            display: table;
            margin: 0 auto;
            position: relative;
            width: 100%;
            height: 100%;
            max-width: 1500px;
        }

        .tuition-header {
            * {
                color: #FFF4EE;
            }

            padding-top: 200px;
            text-align: center;
            padding-bottom: 30px;

            h1 {
                margin: 0;
                font-family: "Andika", sans-serif;
                position: relative;
                z-index: 9;
            }

            p {
                margin: 0;
                position: relative;
                z-index: 9;
            }

            .tution-head-img {
                position: absolute;
                left: 0;
                top: 0;
                width: 50%;
                height: 325px;

                img {
                    height: 100%;
                    object-fit: contain;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                }

                .tution-img1 {
                    z-index: 99;
                    width: 34%;
                }

                .tution-img2 {
                    z-index: 9;
                    width: 80%;
                    left: 25%;
                    top: -20%;
                }

                .tution-img3 {
                    width: 70%;
                    height: 100%;
                    object-position: left;
                    object-fit: contain;
                }

                @media screen and (max-width: 767px) {
                    & {
                        width: 85%;
                    }
                }
            }
        }

        .clouds-float-wrp.f-wrp {
            position: absolute;
            left: 0;
            height: 100%;
            width: 100%;
            top: 0;

            span {
                width: 100%;
                display: block;
                position: absolute;
                overflow: hidden;

                &:nth-child(1) {
                    top: 35%;

                    img {
                        position: relative;
                        width: 70px;
                        animation: cloudAnimation 15s linear infinite;
                    }
                }

                &:nth-child(2) {
                    top: 45%;

                    img {
                        position: relative;
                        width: 75px;
                        animation: cloudAnimation 20s linear infinite;
                    }
                }

                &:nth-child(3) {
                    top: 72%;

                    img {
                        position: relative;
                        width: 50px;
                        animation: cloudAnimation 25s linear infinite;
                    }
                }

                &:nth-child(4) {
                    top: 95%;

                    img {
                        position: relative;
                        width: 100px;
                        animation: cloudAnimation 25s linear infinite;
                    }
                }
            }

            @keyframes cloudAnimation {
                0% {
                    right: 10%;
                }

                100% {
                    right: -110%;
                }
            }
        }

        .tuition-fee-structure {
            * {
                color: #F5FFFD;
            }

            @media screen and (max-width: 767px) {
                & {
                    .row>div {
                        margin-bottom: 25px;
                    }
                }
            }

            .fee-sec-wrp {
                display: flex;
                flex-direction: column;
                height: 100%;
                max-width: 400px;
                margin: 0 auto;
                float: none;

                .fee-sec-img {
                    .fee-sec-icon {
                        position: relative;
                        left: 0;
                        top: 50px;
                        display: block;
                        width: 100%;
                        height: 100%;
                        right: 0;
                        margin: 0 auto;
                        z-index: 9;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                            object-position: center;
                            max-width: 225px;
                            margin: 0 auto;
                        }
                    }

                    @media screen and (max-width: 767px) {
                        & {
                            display: none;
                        }
                    }
                }
            }

            .each-fee-sec {
                background: #440F5A;
                border-radius: 15px;
                padding: 40px 20px;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                h3 {
                    font-family: "Andika", sans-serif;
                    text-align: center;
                    font-weight: 700;
                    font-size: 22px;
                }

                .trip-grid {
                    display: flex;
                    padding-bottom: 25px;

                    div {
                        flex: 1;
                        margin-right: 5px;
                    }

                    div:last-child {
                        max-width: 33.33%;
                        margin-right: 0px;
                    }

                    div:nth-child(1) {
                        border-top: 5px solid #B7FF5F;

                        b {
                            color: #B7FF5F;
                        }
                    }

                    div:nth-child(2) {
                        border-top: 5px solid #81D817;

                        b {
                            color: #81D817;
                        }
                    }

                    div:last-child {
                        border-top: 5px solid #257D28;

                        b {
                            color: #257D28;
                        }
                    }

                    span {
                        b {
                            display: block;
                        }
                    }
                }

                .pay-table {
                    table {
                        width: 100%;

                        tr {
                            td:last-child {
                                text-align: right;
                            }

                            td {
                                .price {
                                    background-color: #257D28;
                                    padding: 5px 15px;
                                    display: inline-block;
                                    border-radius: 5px;
                                    margin-top: 10px;
                                }
                            }

                            &:first-child {
                                td {
                                    .price {
                                        margin-top: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .tuition-info-txt {
                padding: 25px 20px;
                max-width: max-content;
                border-radius: 16px;
                background: #E94F1B;
                margin-top: 30px;

                h2 {
                    font-weight: 700;
                    margin: 0;
                    font-size: 24px;
                    font-family: "Andika", sans-serif;
                }

                @media screen and (max-width: 991px) {
                    & {
                        width: 100%;
                        max-width: 100%;
                        text-align: center;
                    }
                }
            }

        }


    }






}