.admin-portal {
    background: #CFC1D5;
    min-height: 100vh;
    max-width: 100vw;

    .portal-main-structure {
        min-height: 100vh;
        max-width: 1920px;
        margin: 0 auto;

        .app-portal-header {
            background: transparent;
            box-shadow: none;

            .MuiToolbar-root.MuiToolbar-regular {
                min-height: auto;
                padding-left: 0;
                padding-right: 0;
                padding-bottom: 24px;
            }

            .MuiTypography-root {
                color: #8032A1;
                font-family: 'Andika', sans-serif;
            }
        }

        .panel-main-content {
            .grid-sec-wrp {
                border-radius: 24px;
                background: #FFFFFF;
                padding: 0px 24px 24px 0px;
                width: 100%;
                margin: 0;
            }
        }
    }

    .provider-drawer {
        &>.MuiDrawer-paper {
            background: #FFFFFF;
            justify-content: space-between;

            &::-webkit-scrollbar {
                width: 0px;
            }

            &::-webkit-scrollbar-track {
                background: #F5F0E7;
            }

            &::-webkit-scrollbar-thumb {
                background: #8032A1;
                border-radius: 50px;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: #8032A1;
            }

            .MuiDivider-fullWidth {
                width: calc(100% - 30px);
                margin: 0 auto;
                border-color: #FFF4EE;
            }
        }

        .logo-sec-wrp {
            min-height: 120px;
            transition: 0.5s all;

            .logo-img {
                display: block;
                width: 100%;
                padding: 20px 0 40px;
                img{
                    width: 90px;
                    margin: 0 auto;
                }
            }
        }

        ul {
            * {
                color: #B4AC9C;
                transition: 0.5s all;
            }

            li {
                .listButton {
                    padding: 15px 20px;
                    position: relative;

                    .MuiListItemIcon-root {
                        span {
                            display: flex;

                            svg {
                                path {
                                    fill: #B4AC9C;
                                }

                                rect {
                                    fill: #FFFFFF4D;
                                }
                            }
                        }
                    }

                    &.logout {
                        span {
                            font-weight: 700;

                            svg {
                                path {
                                    fill: none;
                                    stroke: #B4AC9C;
                                }
                            }
                        }
                    }

                    &:hover {
                        * {
                            color: #0F0A01;
                            transition: 0.5s all;
                        }

                        .MuiListItemIcon-root {
                            span {
                                svg {
                                    path {
                                        fill: #8032A1;
                                    }

                                    rect {
                                        fill: #FFFFFF4D;
                                    }
                                }
                            }
                        }

                        &.logout {
                            span {
                                svg {
                                    path {
                                        fill: none;
                                        stroke: #8032A1;
                                    }
                                }
                            }
                        }
                    }
                }

                &:hover,
                &.active {
                    * {
                        color: #0F0A01;
                        transition: 0.5s all;
                    }

                    .listButton {
                        background-color: #FFFFFF;
                    }

                    .MuiListItemIcon-root {
                        span {
                            svg {
                                path {
                                    fill: #8032A1;
                                }

                                rect {
                                    fill: #FFFFFF4D;
                                }
                            }
                        }
                    }

                    &.logout {
                        span {
                            svg {
                                path {
                                    fill: none;
                                    stroke: #8032A1;
                                }
                            }
                        }
                    }
                }

                &::after {
                    content: "";
                    width: 6px;
                    height: calc(100% - 10px);
                    position: absolute;
                    top: 5px;
                    right: 0;
                    background: #8032A1;
                    border-radius: 5px 0 0 5px;
                    opacity: 0;
                    transition: 0.5s all;
                }

                &:hover:after,
                &.active:after {
                    opacity: 1;
                    transition: 0.5s all;
                }
            }

        }

        &.MuiDrawer-docked {
            .logo-sec-wrp {
                min-height: 60px;
                transition: 0.5s all;
            }
        }
    }

    .panel-main-body {
        width: calc(100% - 240px);
    }

    .tab-sec-wrp {
        .tab-header {
            button {
                color: #B4AC9C;
                background: rgba(255, 255, 255, 0.50);

                &.Mui-selected {
                    color: #0F0A01;
                    background: #FFFFFF;
                }
            }

            .MuiTabs-indicator {
                background: #FFFFFF;
            }
        }

        .tab-body {
            background: #FFFFFF;
        }
    }

    .provider-table {
        .MuiDataGrid-root.MuiDataGrid-withBorderColor {
            border-color: transparent;
        }

        .app-grid-tool-bar {
            flex-direction: row;
            justify-content: space-between;

            .MuiFormControl-root {
                max-width: 250px;
            }
        }

        .MuiDataGrid-columnHeaders,
        .MuiDataGrid-footerContainer {
            border-color: transparent;
        }
    }


    .listButton {

        &.pricing,
        &.payments,
        &.inbox,
        &.facilities,
        &.dashboard {
            .MuiListItemIcon-root {
                span {
                    svg {
                        path {
                            fill: none !important;
                            stroke: #B4AC9C;
                        }

                        rect {
                            fill: #B4AC9C !important;
                        }
                    }
                }
            }

            &:hover {
                .MuiListItemIcon-root {
                    span {
                        svg {
                            path {
                                fill: none !important;
                                stroke: #8032A1 !important;
                            }

                            rect {
                                fill: #FFFFFF4D !important;
                            }
                        }
                    }
                }

            }
        }

    }

    .accident-sec-wrp {
        .standard-form.form-full-width {
            &>.info-head {
                display: none;
                & + .form-slider-box{
                    margin-top: 0;
                }
            }
        }
    }

}